.stat-scrollbar-item {
  background-color: hsl(0, 0%, 14%);
  border: 1px solid white;
  border-radius: 50px;
  width: 100%;
  min-width: 50px;
  height: 30px;
  padding: 6px 0px;
  font-size: 13px;
  font-weight: 700;
  margin: 0px 5px;
  cursor: pointer;
}

.stat-scrollbar-item:hover {
  background-color: hsl(0, 0%, 27%);
  transition: 0.1s;
}

.selected {
  background-color: var(--clr-neon);
  border: 1px solid var(--clr-neon);
}

.selected:hover {
  background-color: var(--clr-neon);
  border: 1px solid var(--clr-neon);
}

.sort-by-rank-button-down {
  font-size: 21px;
  cursor: pointer;
  font-family: "Verdana";
  font-weight: 700;
  transform: scale(1, -1);
  padding-top: 20px;
}

.sort-by-rank-button-up {
  font-size: 21px;
  margin-right: 2vw;
  cursor: pointer;
  font-family: "Verdana";
  font-weight: 700;
  padding-top: 5px;
}
