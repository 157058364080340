.popup {
  position: fixed;
  width: 100vw;
  height: 100vh;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 2;
  /* visibility: hidden; */
  opacity: 0;
  transition: 0.64s ease-in-out;
}
.target {
  visibility: visible;
  opacity: 1;
}

.target .popup-inner {
  bottom: 0;
  right: 0;
  transform: rotate(0);
}
