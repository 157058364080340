:root {
  --clr-neon: hsl(203 100% 54%);
  --clr-bg: hsl(221 13% 18%);
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media only screen and (max-width: 608px) {
  .gfi-logo {
    width: 200px !important;
    height: 82px !important;
  }

  .main-title {
    font-size: 20px !important;
    margin: 2vh 0 0 !important;
  }

  .secondary-title {
    font-size: 19px !important;
  }
}

@media only screen and (max-width: 398px) {
  .neon-button {
    font-size: 0.85em !important;
    padding: 0.25em 0.2em !important;
    margin: 2vh 0vw !important;
  }

  .main-title {
    font-size: 14.5px !important;
    margin: 2.3vh 0 0 !important;
  }

  .secondary-title {
    font-size: 14.5px !important;
  }

  .gfi-logo {
    width: 180px !important;
    height: 73px !important;
  }
}

/* @media only screen and (max-width: 465px) and (min-width: 415px) {
  .neon-button {
    font-size: 0.9em !important;
    
} */

@media only screen and (max-width: 465px) and (min-width: 398px) {
  .gfi-logo {
    width: 180px !important;
    height: 73px !important;
  }

  .neon-button {
    font-size: 0.9em !important;
    padding: 0.25em 0.2em !important;
    margin: 2vh 0vw !important;
  }

  .main-title {
    font-size: 16px !important;
    margin: 2.3vh 0 0 !important;
  }

  .secondary-title {
    font-size: 15px !important;
  }
}

@media only screen and (max-width: 1180px) {
  .navigation {
    display: none !important;
  }

  .navigation-below {
    display: inherit !important;
  }

  .neon-button {
    margin: 2.5vh 2.5vw 4.5vh 2.5vh !important;
  }
}

@media only screen and (min-width: 1180px) {
  .navigation {
    display: inherit !important;
  }

  .navigation-below {
    display: none !important;
  }
}

.App {
  height: 100vh;
  width: 100vw;
  text-align: center;
  background: var(--clr-bg);
  color: var(--clr-neon);
  font-family: "Helvetica";
  display: flex;
  flex-direction: column;
}

.ad-modal {
  position: fixed; /* Stay in place */
  z-index: 99; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.671); /* Black w/ opacity */
}

.ad-modal-content {
  margin: auto;
  padding: 20px;
  width: 100%;
}

.neon-button {
  text-align: center;
  margin: 5vh 2vw;
  font-size: 1em;
  cursor: pointer;
  color: var(--clr-neon);
  text-decoration: none;
  border: var(--clr-neon) 0.1em solid;
  border-radius: 0.25em;
  padding: 0.25em 1em;
  text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.23), 0 0 0.125em currentColor;
  box-shadow: 0 0 0.5em 0 var(--clr-neon), inset 0 0 0.4em 0 var(--clr-neon);
  position: relative;
  width: 20vw;
  z-index: 1;
}

.neon-button::before {
  pointer-events: none;
  content: "";
  position: absolute;
  background: var(--clr-neon);
  top: 120%;
  left: 0;
  width: 100%;
  height: 100%;

  transform: perspective(1em) rotateX(40deg) scale(1, 0.35);
  filter: blur(1em);
  opacity: 0.7;
}

.neon-button::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0 0 2em 0.5em var(--clr-neon);
  opacity: 0;
  background-color: var(--clr-neon);
  z-index: -1;
  transition: opacity 100ms linear;
}

.neon-button:hover,
.neon-button:focus {
  color: var(--clr-bg);
  text-shadow: none;
}

.neon-button:hover::before,
.neon-button:focus::before {
  opacity: 1;
}
.neon-button:hover::after,
.neon-button:focus::after {
  opacity: 1;
}

/* Header  */
.header {
  display: flex;
  justify-content: center;
}

.header-title-container {
  display: flex;
  flex-direction: column;
}

.main-title {
  color: white;
  font-family: "Arial";
  font-size: 25px;
  margin: 3.2vh 0 0;
  border-bottom: 0.5px solid white;
}

.secondary-title {
  color: var(--clr-neon);
  font-family: "Arial";
  font-size: 24px;
  margin: 0.3vh 0 0;
  text-align: left;
}

.gfi-logo {
  height: 123px;
  width: 300px;
  margin: 0.5vh 0 0;
}

/* Navigation */
.navigation {
  display: flex;
  margin: 0 auto;
  width: 50vw;
  justify-content: center;
}

.navigation-below {
  display: flex;
  margin: 0 auto;
  width: 99vw;
  justify-content: center;
}

/* Content */
.content-container {
  /* background-color: hsl(221 13% 25%); */
  background-color: lightgrey;
  height: 85vh;
  overflow: auto;
  display: flex;
  /* filter: blur(0.05em); */
  border-top: 0.8px solid hsl(203 100% 60%);
}
