@media only screen and (min-width: 200px) {
  .news-article-image {
    width: 350px;
    height: 350px;
  }
}

@media only screen and (min-width: 750px) {
  .news-article-image {
    width: 400px;
    height: 400px;
  }
}

@media only screen and (min-width: 1000px) {
  .news-article-image {
    width: 600px;
    height: 600px;
  }
}
